export const rejectMarkOption = [
    {
        label: '平台初审驳回',
        value: "00"
    },
    {
        label: '平台复核驳回',
        value: "01"
    },
    {
        label: '门店打款驳回',
        value: "02"
    },
    {
        label: '门店审核驳回',
        value: "03"
    },
    {
        label: '回收商撤回申请',
        value: "04"
    }
]

export const formItemList = [
    {
        labelName: "订单号",
        key: 'orderNo',
        type: "input",
        lableWidth: "58px",
        placeholder: "请输入查询",
    },
    {
        labelName: "外部订单号",
        key: 'outOrderNo',
        type: "input",
        lableWidth: "90px",
        placeholder: "请输入查询",
    },
    {
        labelName: "回收商家",
        key: 'merchantId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "询价商家",
        key: 'companyId',
        childrenKey: "storeId",
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "询价门店",
        fatherKey: "companyId",
        key: 'storeId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        disabled: true,
        option: [],
        clearFun: () => false
    },
    // 新加矩阵原商家
    {
        labelName: "矩阵原商家",
        key: 'oriCompanyId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '90px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "下单店员",
        key: 'staffName',
        type: "input",
        lableWidth: "70px",
        placeholder: "请输入查询",
    },
    {
        labelName: "机型",
        key: 'phoneName',
        type: "input",
        lableWidth: "43px",
        placeholder: "请输入机型名称查询",
    },
    {
        labelName: "串号",
        key: 'imei',
        type: "input",
        lableWidth: "43px",
        placeholder: "请输入串号查询",
    },
    {
        key: 'orderType',
        type: 'select',
        lableWidth: '98px',
        placeholder: "请选择",
        labelName: '是否矩阵订单',
        option: [
            {
                label: '全部',
                value: null
            },
            {
                label: '是',
                value: '01'
            },
            {
                label: '否',
                value: "02"
            }
        ],
    },
    {
        key: 'outOrder',
        type: 'select',
        lableWidth: '98px',
        placeholder: "请选择",
        labelName: '是否外部订单',
        option: [
            {
                label: '全部',
                value: null
            },
            {
                label: '是',
                value: '01'
            },
            {
                label: '否',
                value: "00"
            }
        ],
    },
    {
        labelName: "撮合渠道",
        key: 'channelId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '72px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "初审人",
        key: 'auditFirstAdminId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "复核人",
        key: 'auditReAdminId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        lableWidth: "80px",
        labelName: "申请时间",
        key: 'applyTime',
        type: "daterange",
    },
    // 新增成交时间
    {
        lableWidth: "80px",
        labelName: "成交时间",
        key: 'ackTime',
        type: "daterange",
    },
    // 新加初审时间
    {
        lableWidth: "80px",
        labelName: "初审时间",
        key: 'auditFirstTime',
        type: "daterange",
    },
    {
        lableWidth: "72px",
        labelName: "复核时间",
        key: 'auditTime',
        type: "daterange",
    },
    {
        lableWidth: "80px",
        labelName: "关闭时间",
        key: 'closeTime',
        type: "daterange",
    },
    {
        labelName: "关闭原因",
        key: 'rejectMark',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        option: rejectMarkOption,
        clearFun: () => false
    },
    {slotName:'applyAmount'},
    {slotName:'catalogId'},
    {slotName:'itemId'},
    // 追差款使用
    // {
    //     key: 'moneyUseTypeList',
    //     type: 'select',
    //     lableWidth: '98px',
    //     placeholder: "请选择",
    //     labelName: '追差款使用',
    //     option: [
    //         {
    //             label: '仅使用差异基金',
    //             value: '00'
    //         },
    //         {
    //             label: '仅门店打款',
    //             value: "01"
    //         },
    //         {
    //             label: '差异基金+门店打款',
    //             value: "02"
    //         }
    //     ],
    // },
    {slotName:'moneyUseTypeList'},
    // 打款方式
    {
        key: 'moneyWay',
        type: 'select',
        lableWidth: '98px',
        placeholder: "请选择",
        labelName: '打款方式',
        option: [
            {
                label: '线上打款',
                value: '01'
            },
            {
                label: '线下打款',
                value: "00"
            }
        ],
    },
    {slotName:'staAdminId'},//暂存人
]

export const tabData = [
    {
        text: "平台待审核",
        value: "00",
        num: 0,
        key: "waitPNum",
        staState:'00' //00：无暂存，01：暂存
    },
    {
        text: "复核打款",
        value: "01",
        num: 0,
        key: "waitPayNum",
        staState:'00' //00：无暂存，01：暂存
    },
    {
        text: "待门店打款",
        value: "02",
        num: 0,
        key: "waitCompanyPayNum",
        staState:'00' //00：无暂存，01：暂存
    },
    {
        text: "追差完成",
        value: "04",
        num: 0,
        key: "finishNum",
        staState:'00' //00：无暂存，01：暂存
    },
    {
        text: "追差关闭",
        value: "G10",
        num: 0,
        key: "closeNum",
        staState:'00' //00：无暂存，01：暂存
    },
    {
        text: "暂存订单",
        value: "",
        num: 0,
        key: "temporaryNum",
        staState:'01' //00：无暂存，01：暂存
    }
]

export const tableData = {
    loading: false,
    // 当前数据
    currentData: {},
    // 表数据
    data: [],
    // 表列
};

// 表列
export const platformTableColumns = [
    {
        prop: "photoList",
        label: "旧机照片",
    },
    {
        prop: "phoneName",
        label: "机型",
    },
    {
        prop: "imei",
        label: "串号",
    },
    {
        prop: "createCompanyName",
        label: "门店商户",
    },
    {
        prop: "createStoreName",
        label: "下单门店",
    },
    {
        prop: "createStaffInfo",
        label: "下单店员",
    },
    {
        prop: "quoteMerchants",
        label: "参与报价回收商",
    },
    {
        prop: "orderState",
        label: "订单状态",
    },
    {
        prop: "applyStaffInfo",
        label: "申请人",
    },
    {
        prop: "manualQuotePrice",
        label: "回收金额(含差异基金)"
    },
    {
        prop: "items",
        label: "追差内容",
    },
    {
        prop: "arguePrice",
        label: "申请追差金额",
    },
    {
        prop: "auditFirstInfo",
        label: "平台初审人/审核时间",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "canArguePrice",
        label: "实际追差金额",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "auditPrice",
        label: "初审通过金额",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "auditInfo",
        label: "平台复核人/审核时间",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "rePayPrice",
        label: "复核认差金额",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "mdq",
        label: "门店待打款",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "rejectMarkText",
        label: "关闭原因",
        // 控制是否隐藏当前
        isHide: true
    },
    // 待对接
    // {
    //     prop: "cancelStaffInfo",
    //     label: "撤回操作人",
    //     // 控制是否隐藏当前
    //     isHide: true
    // },
    {
      prop:'closeInfo',
      label:"操作人",
      isHide: true
    },
    // 待对接
    {
        prop: "closeActionTime",
        label: "关闭时间",
        // 控制是否隐藏当前
        isHide: true
    },
    // 暂存订单 新增 ***
    {
        prop: "staAdminName",
        label: "暂存人",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "staTime",
        label: "暂存时间",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "staCause",
        label: "暂存原因",
        // 控制是否隐藏当前
        isHide: true
    },
    // 备注
    {
        prop: "noteNum",
        width: '180px',
        label: "备注"
    },
    {
        prop: "isImportUpAccount",
        width: '100px',
        label: "是否导入上账",
        isHide: true,
    },
    {
        prop: "honorState",
        width: '180px',
        label: "外部追差状态",
        isHide: true,
    },
    {
        prop: "arguePriceHonor",
        width: '180px',
        label: "外部申请/通过金额",
        isHide: true,
    },
    // ***
    {
        prop: "action",
        width: '200px',
        label: "操作"
    }
]

// 责任列表
export const responsibilityData = [
    {
        label: "门店责任",
        value: "C"
    },
    {
        label: "回收商责任(驳回追差)",
        value: "M"
    },
    {
        label: "双方共同责任",
        value: "T"
    },
]

//责任切换弹窗提示
export const switchResponsibility = {
    title: '切换提示',
    show: false,
    isTip: true,
    tipText: '切换选项会清空已填写的信息，是否确认继续操作？',
    newValue: '',
}


//确认扣款余额设置
export const deBalanceVoucherProps = {
    title: "确认扣款商户余额",
    show: false,
    headerMessage: "说明：请谨慎确认追差款项从商户余额扣款，并上传沟通凭证",
    isView:false//是否只是查看
}

export const photoDialogProp = {
    show: false,
    photoArr: []
}


// 驳回差异
export const rejectProp = {
    show:false,
    title:'驳回差异',
    currentData:{},
    headerMessage:"说明：驳回差异默认回收商责任，需重新填写判责依据，确认驳回后将推送给回收商",
    judgements:[],
    isReset:false,
}
